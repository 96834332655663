import { Box, Flex } from '@vapordex/uikit';
import styled from 'styled-components';

export const StyledSwapContainer = styled(Flex)<{ isChartExpanded: boolean }>`
  flex-shrink: 0;
`;

export const StyledInputCurrencyWrapper = styled(Box)`
  width: 366px;
  > div {
    border-radius: 10px;
    background: transparent;
    > div {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.8);
    }
  }
`;
