import useSWR from 'swr';
import useActiveWagmi from './useActiveWagmi';
import { Currency } from '@vapordex/sdk';
import { WRAPPED_NATIVE_CURRENCY } from '@vaporfi/utils';
import { wrappedCurrency } from 'utils/wrappedCurrency';
import { Address } from 'wagmi';
import { useUserSingleHopOnly } from '@state/user/hooks';
import { parseUnits } from 'viem';
import { ZERO } from '@config/constants';
import { singleUrlFetcher } from '@utils/api/fetchers';
import { useAggregatorRouterAddress } from './useContract';
export interface Offer {
  adapters: readonly Address[];
  amounts: readonly bigint[];
  path: readonly Address[];
  inputCurrency: Currency;
  outputCurrency: Currency;
}

export const useAggregatorOffer = (
  inputCurrency: Currency,
  outputCurrency: Currency,
  amountIn: string,
  isSwapPending: boolean,
): {
  offer: Offer;
  refetch: () => void;
} => {
  const { chainId } = useActiveWagmi();
  const [isSingleHopOnly] = useUserSingleHopOnly();
  const contractAddress = useAggregatorRouterAddress();

  const fromAVAX = inputCurrency?.isNative;
  const toAVAX = outputCurrency?.isNative;
  const tokenIn = fromAVAX
    ? WRAPPED_NATIVE_CURRENCY[chainId]
    : wrappedCurrency(inputCurrency, chainId);
  const tokenOut = toAVAX
    ? WRAPPED_NATIVE_CURRENCY[chainId]
    : wrappedCurrency(outputCurrency, chainId);

  const shouldFetch = Number.parseFloat(amountIn) > 0;

  const url = shouldFetch
    ? `/api/swap/multipleQuotes?router=${contractAddress}&chainId=${chainId}&amountIn=${
        amountIn ? parseUnits(amountIn, inputCurrency?.decimals) : '0'
      }&tokenIn=${tokenIn?.wrapped?.address}&tokenOut=${tokenOut?.wrapped
        ?.address}&singleHopOnly=${isSingleHopOnly ? 'true' : 'false'}`
    : null;

  const {
    data,
    error,
    mutate: refetch,
  } = useSWR(url, singleUrlFetcher, {
    refreshInterval: shouldFetch && !isSwapPending ? 5000 : 0,
  });

  if (!data || error) {
    return {
      offer: {
        adapters: [],
        amounts: [],
        inputCurrency,
        outputCurrency,
        path: [],
      },
      refetch,
    };
  }

  let offer: Offer | undefined;
  if (
    !error &&
    data &&
    data['Best Path'] &&
    data['Best Path'].amounts &&
    data['Best Path'].amounts.length > 0 &&
    data['Best Path'].amounts.at(-1) &&
    BigInt(data['Best Path'].amounts.at(-1)) > ZERO
  ) {
    const bestPath = data['Best Path'];
    offer = {
      adapters: bestPath.adapters,
      amounts: bestPath.amounts.map(BigInt),
      inputCurrency,
      outputCurrency,
      path: bestPath.path,
    };
  }
  return {
    offer: offer || {
      adapters: [],
      amounts: [],
      inputCurrency,
      outputCurrency,
      path: [],
    },
    refetch,
  };
};
