import { PageMeta } from '@components/Layout/Page';
import { Flex } from '@vapordex/uikit';
import { useMatchBreakpointsContext } from '@vapordex/uikit';
import styled from 'styled-components';

const StyledPage = styled.div<{
  removePadding: boolean;
  isMobile: boolean;
  noMinHeight;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${({ removePadding }) => (removePadding ? '0' : '16px')};
  padding-bottom: 0;
  min-height: ${({ isMobile, noMinHeight }) =>
    noMinHeight
      ? 'initial'
      : isMobile
      ? 'calc(100vh - 164px)'
      : 'calc(100vh - 64px)'};

  ${({ theme }) => theme.mediaQueries.xs} {
    background-size: auto;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: ${({ removePadding }) => (removePadding ? '0' : '24px')};
    padding-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: ${({ removePadding }) => (removePadding ? '0' : '32px')};
    padding-bottom: 0;
    min-height: ${({ noMinHeight }) =>
      noMinHeight ? 'initial' : 'calc(100vh - 100px)'};
  }
`;

const Page: React.FC<
  React.PropsWithChildren<
    React.HTMLAttributes<HTMLDivElement> & {
      removePadding?: boolean;
      hideFooterOnDesktop?: boolean;
      noMinHeight?: boolean;
    }
  >
> = ({ children, noMinHeight = false, removePadding = false, ...props }) => {
  const { isMobile } = useMatchBreakpointsContext();

  return (
    <>
      <PageMeta />
      <StyledPage
        removePadding={removePadding}
        isMobile={isMobile}
        noMinHeight={noMinHeight}
        {...props}
      >
        {children}
        <Flex flexGrow={1} />
      </StyledPage>
    </>
  );
};

export default Page;
