import QuestionHelper from '../QuestionHelper';
import Transactions from './Transactions';
import GlobalSettings from '@components/Menu/GlobalSettings';
import {
  Text,
  Flex,
  Heading,
  IconButton,
  ArrowBackIcon,
  NotificationDot,
} from '@vapordex/uikit';
import Link from 'next/link';
import { useExpertModeManager } from 'state/user/hooks';
import styled from 'styled-components';

interface Props {
  title: string;
  subtitle: string;
  helper?: string;
  backTo?: string | (() => void);
  noConfig?: boolean;
}

const AppHeaderContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`;

const AppHeader: React.FC<React.PropsWithChildren<Props>> = ({
  backTo,
  helper,
  noConfig = false,
  subtitle,
  title,
}) => {
  const [expertMode] = useExpertModeManager();

  return (
    <AppHeaderContainer>
      <Flex alignItems="center" width="100%" style={{ gap: '16px' }}>
        {backTo &&
          (typeof backTo === 'string' ? (
            <Link passHref href={backTo} legacyBehavior>
              <IconButton as="a" scale="sm">
                <ArrowBackIcon width="32px" />
              </IconButton>
            </Link>
          ) : (
            <IconButton scale="sm" variant="text" onClick={backTo}>
              <ArrowBackIcon width="32px" />
            </IconButton>
          ))}
        <Flex flexDirection="column" width="100%">
          <Flex mb="8px" alignItems="center" justifyContent="space-between">
            <Heading as="h2">{title}</Heading>
            {!noConfig && (
              <Flex alignItems="center">
                <NotificationDot show={expertMode}>
                  <GlobalSettings />
                </NotificationDot>
                <Transactions />
              </Flex>
            )}
          </Flex>
          <Flex alignItems="center">
            {helper && (
              <QuestionHelper
                className="mr-1"
                text={helper}
                placement="top-start"
              />
            )}
            <Text color="textSubtle" fontSize="14px">
              {subtitle}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </AppHeaderContainer>
  );
};

export default AppHeader;
