import TransactionsModal from './TransactionsModal';
import { HistoryIcon, IconButton } from '@vapordex/uikit';
import { useModal } from '@vaporfi/hooks';
const Transactions = () => {
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />);
  return (
    <>
      <IconButton
        scale="sm"
        variant="text"
        onClick={onPresentTransactionsModal}
      >
        <HistoryIcon color="textSubtle" width="24px" />
      </IconButton>
    </>
  );
};

export default Transactions;
