import Svg from '../Svg';
import { SvgProps } from '../types';
import * as React from 'react';

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.3799 20.0608C15.9033 20.0608 20.3799 15.5842 20.3799 10.0608C20.3799 4.53735 15.9033 0.060791 10.3799 0.060791C4.85645 0.060791 0.379883 4.53735 0.379883 10.0608C0.379883 15.5842 4.85645 20.0608 10.3799 20.0608ZM8.81738 13.1858H9.75488V10.6858H8.81738C8.29785 10.6858 7.87988 10.2678 7.87988 9.74829C7.87988 9.22876 8.29785 8.81079 8.81738 8.81079H10.6924C11.2119 8.81079 11.6299 9.22876 11.6299 9.74829V13.1858H11.9424C12.4619 13.1858 12.8799 13.6038 12.8799 14.1233C12.8799 14.6428 12.4619 15.0608 11.9424 15.0608H8.81738C8.29785 15.0608 7.87988 14.6428 7.87988 14.1233C7.87988 13.6038 8.29785 13.1858 8.81738 13.1858ZM10.3799 7.56079C9.68848 7.56079 9.12988 7.0022 9.12988 6.31079C9.12988 5.61938 9.68848 5.06079 10.3799 5.06079C11.0713 5.06079 11.6299 5.61938 11.6299 6.31079C11.6299 7.0022 11.0713 7.56079 10.3799 7.56079Z"
        fill="#14D3ED"
      />
    </Svg>
  );
};

export default Icon;
