export const MAX_STEPS = 4;
export const GAS_PRICE = 225;

export const ADDRESSES = {
  adapters: {
    '0x01e5C45cB25E30860c2Fb80369A9C27628911a2b': {
      platform: 'vapordex',
    },
  },
};
