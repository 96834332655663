import { getChainIdCookie } from '@config/constants/networks';
import { useAllTokens } from '@hooks/useCurrency';
import useActiveWagmi from '@hooks/useActiveWagmi';
import { Offer } from '@hooks/useAggregator';
import { Flex, ChevronRightIcon } from '@vapordex/uikit';
import { Fragment, memo } from 'react';
import { unwrappedToken } from 'utils/wrappedCurrency';
import { Typography } from '@vaporfi/uikit';

export default memo(function SwapRoute({ offer }: { offer: Offer }) {
  const { chainId, chains } = useActiveWagmi();
  const allTokens = useAllTokens(
    Number(chains['id'] === chainId ? chainId : getChainIdCookie()),
  );
  return (
    <Flex
      flexWrap="wrap"
      width="100%"
      justifyContent="flex-end"
      alignItems="center"
    >
      {offer?.path?.map((token, i, path) => {
        const isLastItem: boolean = i === path.length - 1;
        const currency = unwrappedToken(allTokens[token]);
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={i}>
            <Flex alignItems="end">
              <Typography
                data-testid={`swap-route-item-${i}`}
                size="sm"
                style={{
                  marginLeft: 'var(--space-half)',
                  marginRight: 'var(--space-half)',
                }}
              >
                {currency?.symbol}
              </Typography>
            </Flex>
            {!isLastItem && <ChevronRightIcon width="12px" />}
          </Fragment>
        );
      })}
    </Flex>
  );
});
