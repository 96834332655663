import { useRouter } from 'next/router';
import { useAppDispatch } from '../index';
import {
  Field,
  selectCurrency,
  switchCurrencies,
  typeInput,
  setRecipient,
} from './actions';
import { Currency, Token } from '@vapordex/sdk';
import { useCallback } from 'react';

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void;
  onSwitchTokens: () => void;
  onUserInput: (field: Field, typedValue: string) => void;
  onChangeRecipient: (recipient: string | null) => void;
} {
  const dispatch = useAppDispatch();

  const router = useRouter();
  const { pathname, query, replace } = router;

  const onSwitchTokens = useCallback(() => {
    if (query?.inputCurrency && query?.outputCurrency) {
      replace(
        {
          pathname: pathname,
          query: {
            ...query,
            inputCurrency: query.outputCurrency,
            outputCurrency: query.inputCurrency,
          },
        },
        undefined,
        { shallow: true },
      );
    }
    dispatch(switchCurrencies());
  }, [dispatch, pathname, query, replace]);

  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      dispatch(
        selectCurrency({
          currencyId:
            currency instanceof Token
              ? currency.address
              : currency?.isNative
              ? currency?.symbol
              : '',
          field,
        }),
      );
    },
    [dispatch],
  );

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }));
    },
    [dispatch],
  );

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      dispatch(setRecipient({ recipient }));
    },
    [dispatch],
  );

  return {
    onChangeRecipient,
    onCurrencySelection,
    onSwitchTokens,
    onUserInput,
  };
}
