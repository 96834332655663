import Card from '@components/Card';
import styled from 'styled-components';
import { RowBetween, RowFixed } from '@components/Layout/Row';
import { Text } from '@vapordex/uikit';
import { Warning } from '@mui/icons-material';
import { useMemo } from 'react';

const StyledCard = styled(Card)`
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.colors.warning};
`;

export default function PriceImpactWarning({
  priceImpact,
  status,
}: {
  priceImpact: number;
  status: boolean;
}) {
  return useMemo(
    () => (
      <StyledCard>
        <RowBetween>
          <RowFixed>
            <Warning />
            <Text as="p" paddingLeft="5px">
              Price impact:
            </Text>
          </RowFixed>
          {status && <Text>{priceImpact.toFixed(2)}%</Text>}
        </RowBetween>
      </StyledCard>
    ),
    [priceImpact, status],
  );
}
