import dynamic from 'next/dynamic';
import shouldShowSwapWarning from 'utils/shouldShowSwapWarning';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useToast from '@contexts/ToastsContext/useToast';
import { ApprovalState, useApproveCallback } from '@hooks/useApproveCallback';
import {
  getFiatValuePriceImpact,
  useStablecoinValue,
} from '../hooks/priceImpact';
import { Offer } from '@hooks/useAggregator';
import { useAggregatorRouterAddress } from '@hooks/useContract';
import { useAggregatorSwapCallback } from '@hooks/useAggregatorSwapCallback';
import { useCurrency } from '@hooks/useCurrency';
import useActiveWagmi from '@hooks/useActiveWagmi';
import { AppBody } from '@components/App';
import { Button, Container, Typography } from '@vaporfi/uikit';
import { RowBetween } from '@components/Layout/Row';
import { CurrencyAmount, Currency } from '@vapordex/sdk';
import { useModal } from '@vaporfi/hooks';
import { StyledInputCurrencyWrapper } from '../styles';
import { FailedSwapState } from '../components/States';
import { addTokenToWallet } from '@state/wallet/hooks';
import { Field } from '@state/swap/actions';
import { useAggregatedSwapInfo, useSwapState } from '@state/swap/hooks';
import {
  useUserIsStratosphereMember,
  useUserSlippageTolerance,
} from '@state/user/hooks';
import {
  useIsTransactionFailed,
  usePendingTransactions,
} from '@state/transactions/hooks';
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers';
import { Address } from 'wagmi';
import { maxAmountSpend } from 'utils/maxAmountSpend';
import Link from 'next/link';
import ConnectWalletButton from '@components/ConnectWalletButton';
import { usePostHog } from 'posthog-js/react';
import { useRouter } from 'next/router';
import { ADDRESS_ZERO } from '@config/constants';
import useSWR from 'swr';
import { CurrencyForm } from '@vaporfi/features';
import CurrencyInputPanel from '@/components/CurrencyInputPanel';
import GlobalSettings from '@/components/Menu/GlobalSettings';
import { hasV2VAPE } from '@/utils/addressHelpers';
import { VAPE_V3_ANNOUNCEMENT } from '@vaporfi/utils';

const PendingSwapState = dynamic(() =>
  import('../components/States').then((mod) => mod.PendingSwapState),
);
const SuccessSwapState = dynamic(() =>
  import('../components/States').then((mod) => mod.SuccessSwapState),
);
const SwapSummaryFooter = dynamic(() =>
  import('../components/States').then((mod) => mod.SwapSummaryFooter),
);
const AcceptTermsModal = dynamic(
  () => import('../components/AcceptTermsModal'),
);
const SwapWarningModal = dynamic(
  () => import('../components/SwapWarningModal'),
);

function SwapViewForm({ isChartExpanded, isTermsEnabled }) {
  const { hasPendingTransactions } = usePendingTransactions();
  const { account, isConnected } = useActiveWagmi();
  const [isTransactionComplete, setIsTransactionComplete] =
    useState<boolean>(false);
  const { toastError, toastSuccess } = useToast();
  const [isSwapPending, setIsSwapPending] = useState<boolean>();
  const posthog = usePostHog();

  const { data: termsData } = useSWR(
    `/api/terms?account=${account ?? ADDRESS_ZERO}`,
    (...args) => fetch(...args).then((res) => res.json()),
  );

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance();

  const router = useRouter();

  const { pathname, query, replace } = router;

  // swap state & price data
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
    independentField,
    recipient,
    typedValue,
  } = useSwapState();
  const inputCurrency = useCurrency(inputCurrencyId);
  const outputCurrency = useCurrency(outputCurrencyId);

  const {
    currencies,
    currencyBalances,
    inputError: swapInputError,
    offer,
    parsedAmount,
    parsedOutputAmount,
  } = useAggregatedSwapInfo(
    independentField,
    typedValue?.split('.')[1]?.length > inputCurrency?.decimals
      ? typedValue?.slice(
          0,
          typedValue?.length - (typedValue?.length - inputCurrency?.decimals),
        )
      : typedValue,
    inputCurrency,
    outputCurrency,
    isSwapPending,
  );

  const parsedAmounts = useMemo(
    () => ({
      [Field.INPUT]: parsedAmount,
      [Field.OUTPUT]: parsedOutputAmount,
    }),
    [parsedAmount, parsedOutputAmount],
  );

  const { onCurrencySelection, onSwitchTokens, onUserInput } =
    useSwapActionHandlers();

  const isV2VAPESelected = useMemo(
    () =>
      hasV2VAPE(
        inputCurrencyId?.toLowerCase(),
        outputCurrencyId?.toLowerCase(),
      ),
    [inputCurrencyId, outputCurrencyId],
  );

  const isValid = !isV2VAPESelected && !swapInputError && !!offer;
  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
    },
    [onUserInput],
  );
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput],
  );

  // modal and loading
  const [
    { attemptingTxn, swapErrorMessage, tradeToConfirm, txHash },
    setSwapState,
  ] = useState<{
    tradeToConfirm: Offer | undefined;
    attemptingTxn: boolean;
    swapErrorMessage: string | undefined;
    txHash: string | undefined;
  }>({
    attemptingTxn: false,
    swapErrorMessage: undefined,
    tradeToConfirm: undefined,
    txHash: undefined,
  });
  const isSwapFailed = useIsTransactionFailed(txHash);

  const formattedAmounts = useMemo(
    () => ({
      [dependentField]: parsedAmounts[Field.OUTPUT]?.toFixed(6) ?? '',
      [independentField]: typedValue,
    }),
    [dependentField, independentField, parsedAmounts, typedValue],
  );

  const contractAddress = useAggregatorRouterAddress();
  // check whether the user has approved the router on the input token
  const [approval, approveCallback, , resetApproval] = useApproveCallback(
    parsedAmount,
    contractAddress,
  );

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

  const maxAmountInput: CurrencyAmount<Currency> | undefined = maxAmountSpend(
    currencyBalances[Field.INPUT],
  );

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !isV2VAPESelected &&
    maxAmountInput?.toExact() !== '0' &&
    Number(formattedAmounts[Field.INPUT]) <=
      Number(maxAmountInput?.toExact()) &&
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED));

  // swap warning state
  const [swapWarningCurrency, setSwapWarningCurrency] = useState(null);
  const [onPresentSwapWarningModal] = useModal(
    <SwapWarningModal swapCurrency={swapWarningCurrency} />,
    false,
  );

  const handleInputSelect = useCallback(
    (currencyInput) => {
      setApprovalSubmitted(false); // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, currencyInput);

      if (outputCurrencyId) {
        replace(
          {
            pathname: pathname,
            query: {
              ...query,
              inputAmount: typedValue,
              inputCurrency: currencyInput?.isNative
                ? currencyInput?.symbol
                : currencyInput?.address,
              outputCurrency: outputCurrencyId,
            },
          },
          undefined,
          { shallow: true },
        );
      } else {
        replace(
          {
            pathname: pathname,
            query: {
              ...query,
              inputAmount: typedValue,
              inputCurrency: currencyInput?.isNative
                ? currencyInput?.symbol
                : currencyInput?.address,
            },
          },
          undefined,
          { shallow: true },
        );
      }
      const showSwapWarning = shouldShowSwapWarning(currencyInput);
      if (showSwapWarning) {
        setSwapWarningCurrency(currencyInput);
      } else {
        setSwapWarningCurrency(null);
      }
    },

    [
      onCurrencySelection,
      outputCurrencyId,
      pathname,
      query,
      replace,
      typedValue,
    ],
  );

  const handleHalfInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.divide(2).toSignificant(6));
    }
  }, [maxAmountInput, onUserInput]);

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact());
    }
  }, [maxAmountInput, onUserInput]);

  const handleOutputSelect = useCallback(
    (currencyOutput) => {
      onCurrencySelection(Field.OUTPUT, currencyOutput);
      if (inputCurrencyId) {
        replace(
          {
            pathname: pathname,
            query: {
              ...query,
              inputCurrency: inputCurrencyId,
              outputCurrency: currencyOutput?.isNative
                ? currencyOutput?.symbol
                : currencyOutput?.address,
            },
          },
          undefined,
          { shallow: true },
        );
      } else {
        replace(
          {
            pathname: pathname,
            query: {
              ...query,
              outputCurrency: currencyOutput?.isNative
                ? currencyOutput?.symbol
                : currencyOutput?.address,
            },
          },
          undefined,
          { shallow: true },
        );
      }

      const showSwapWarning = shouldShowSwapWarning(currencyOutput);
      if (showSwapWarning) {
        setSwapWarningCurrency(currencyOutput);
      } else {
        setSwapWarningCurrency(null);
      }
    },
    [onCurrencySelection, inputCurrencyId, pathname, query, replace],
  );

  const [, setIsSwapping] = useState(false);

  const handleSwapStateChange = (isSwapping: boolean) => {
    setIsSwapping(isSwapping);
  };
  const {
    amountOut,
    callback: swapCallback,
    fee,
    refetch,
  } = useAggregatorSwapCallback(
    offer,
    allowedSlippage,
    recipient as Address,
    handleSwapStateChange,
  );

  //get function name to call on aggregator

  //handleSwapWithPermit : start

  //handleSwapWithPermit : end
  const handleSwap = useCallback(() => {
    setIsSwapPending(true);
    if (!swapCallback) {
      return;
    }
    posthog.capture('swap initiated', {
      inputToken: tradeToConfirm?.inputCurrency?.symbol,
      outputToken: tradeToConfirm?.outputCurrency?.symbol,
    });

    setSwapState({
      attemptingTxn: true,
      swapErrorMessage: undefined,
      tradeToConfirm,
      txHash: undefined,
    });

    swapCallback()
      .then((res) => {
        setSwapState({
          attemptingTxn: false,
          swapErrorMessage: undefined,
          tradeToConfirm,
          txHash: res.hash,
        });

        setIsTransactionComplete(true);
        posthog.capture('swap completed', {
          inputToken: tradeToConfirm?.inputCurrency?.symbol,
          outputToken: tradeToConfirm?.outputCurrency?.symbol,
        });
        setApprovalSubmitted(false);
        resetApproval();
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          swapErrorMessage: error.message,
          tradeToConfirm,
          txHash: undefined,
        });
        setIsSwapPending(false);
        posthog.capture('swap failed', {
          inputToken: tradeToConfirm?.inputCurrency?.symbol,
          outputToken: tradeToConfirm?.outputCurrency?.symbol,
        });
      });
  }, [swapCallback, tradeToConfirm, posthog, resetApproval]);

  const handleOnTransactionCompleteClick = () => {
    onUserInput(Field.INPUT, '');
    onUserInput(Field.OUTPUT, '');
    setIsTransactionComplete(false);
    setIsSwapPending(false);
    setSwapState({
      attemptingTxn: false,
      swapErrorMessage: undefined,
      tradeToConfirm: undefined,
      txHash: undefined,
    });
  };

  const handleWatchAsset = useCallback(
    (currr) => {
      addTokenToWallet(currr)
        .then(() =>
          toastSuccess(
            `Successfully added` + ' ' + currr.symbol + ' ' + `to wallet.`,
          ),
        ) //to-do: add translation
        .catch((error) => toastError(`Failed: ${error.message}`));
    },
    [toastError, toastSuccess],
  );

  const [onPresentAcceptModal] = useModal(
    <AcceptTermsModal
      data-testid="swap-accept-terms-modal"
      onConfirm={handleSwap}
    />,
    true,
    true,
    'acceptTermsModal',
  );
  const handlePresentAcceptModal = useCallback(() => {
    if (isTermsEnabled && termsData?.status !== 'Accepted') {
      return onPresentAcceptModal();
    }
    // if (isPermitAvailable) {
    //   return handlePermitSwap()
    // } else {
    return handleSwap();
    // }
  }, [handleSwap, isTermsEnabled, onPresentAcceptModal, termsData?.status]);

  const stablecoinValues = useStablecoinValue(
    parsedAmounts[Field.INPUT],
    parsedAmounts[Field.OUTPUT],
  );
  const fiatValueInput = stablecoinValues
    ? stablecoinValues.fiatValueInput
    : null;
  const fiatValueOutput = stablecoinValues
    ? stablecoinValues.fiatValueOutput
    : null;
  const outputTokenAmount = parsedAmounts[Field.OUTPUT]
    ? Number.parseFloat(parsedAmounts[Field.OUTPUT].toSignificant(6))
    : null;

  const stablecoinPriceImpact = useMemo(() => {
    if (
      fiatValueInput === null ||
      fiatValueOutput === null ||
      outputTokenAmount === null
    ) {
      return null;
    }
    return getFiatValuePriceImpact(fiatValueOutput, outputTokenAmount);
  }, [fiatValueInput, fiatValueOutput, outputTokenAmount]);

  const isStratosphereMember = useUserIsStratosphereMember();
  const currentStatePending =
    (isSwapPending && attemptingTxn) ||
    (isSwapPending && hasPendingTransactions);
  const currentStateComplete =
    !isSwapFailed &&
    !attemptingTxn &&
    !hasPendingTransactions &&
    isTransactionComplete;
  const currentStateInert =
    !isSwapFailed && !attemptingTxn && !isTransactionComplete;
  const currentStateFailed = isSwapFailed;

  useEffect(() => {
    if (swapWarningCurrency) {
      onPresentSwapWarningModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapWarningCurrency]);

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  useEffect(() => {
    if (approval === ApprovalState.APPROVED) {
      refetch();
    }
  }, [approval, refetch]);

  const SummaryFooter = useCallback(() => {
    return (
      <SwapSummaryFooter
        {...{
          allowedSlippage,
          amountOut,
          fee: +fee?.toString(),
          isStratosphereMember,
          offer,
          outputCurrency,
          stablecoinPriceImpact,
          typedValue,
        }}
      />
    );
  }, [
    allowedSlippage,
    fee,
    offer,
    amountOut,
    outputCurrency,
    stablecoinPriceImpact,
    isStratosphereMember,
    typedValue,
  ]);
  const { currencyInputPanel0, currencyInputPanel1, preButtonFooter } = useMemo(
    () => ({
      currencyInputPanel0: () => (
        <CurrencyInputPanel
          {...{
            currency: currencies[Field.INPUT],
            id: 'swap-currency-input',
            label: independentField === Field.OUTPUT && `From`,
            onCurrencySelect: handleInputSelect,
            onHalf: handleHalfInput,
            onMax: handleMaxInput,
            onUserInput: handleTypeInput,
            otherCurrency: currencies[Field.OUTPUT],
            showMaxButton: true,
            // label:independentField === Field.OUTPUT && !showWrap && trade ?  `From (estimated)` :  `From`,
            value: formattedAmounts[Field.INPUT],
          }}
        />
      ),
      currencyInputPanel1: () => (
        <CurrencyInputPanel
          {...{
            currency: currencies[Field.OUTPUT],
            disabledInput: true, // DON'T REMOVE, aggregator doesn't support output amount}
            id: 'swap-currency-output',
            label: independentField === Field.INPUT && `To`,
            onCurrencySelect: handleOutputSelect,
            onUserInput: handleTypeOutput,
            otherCurrency: currencies[Field.INPUT],
            showMaxButton: false,
            value: formattedAmounts[Field.OUTPUT],
          }}
        />
      ),
      preButtonFooter: () => <SummaryFooter />,
    }),
    [
      currencies,
      formattedAmounts,
      handleHalfInput,
      handleInputSelect,
      handleMaxInput,
      handleOutputSelect,
      handleTypeInput,
      handleTypeOutput,
      independentField,
      SummaryFooter,
    ],
  );

  const buttons = useCallback(() => {
    return (
      <div className="mt-3 flex w-full justify-center" style={{ zIndex: 1 }}>
        {account ? (
          swapInputError ? (
            <Button color="red" size="base">
              <Typography>{swapInputError}</Typography>
            </Button>
          ) : showApproveFlow ? (
            <RowBetween>
              <Button
                color="transparent"
                className="umami--click--swap-approve-button"
                onClick={approveCallback}
                disabled={
                  approval !== ApprovalState.NOT_APPROVED ||
                  approvalSubmitted ||
                  isV2VAPESelected
                }
                style={{ width: '48%' }}
                size="base"
              >
                <Typography weight="semibold">
                  {approval === ApprovalState.PENDING
                    ? `Approving`
                    : approvalSubmitted && approval === ApprovalState.APPROVED
                    ? `Approved`
                    : `Approve`}
                </Typography>
              </Button>

              <Button
                className="umami--click--swap-swap-button"
                color={
                  isValid && +stablecoinPriceImpact?.toFixed(1) > 5
                    ? 'red'
                    : 'blue'
                }
                onClick={() => {
                  handlePresentAcceptModal();
                }}
                style={{ width: '48%' }}
                size="base"
                id="swap-button"
                data-testid="swap-button"
                disabled={
                  !isValid ||
                  approval !== ApprovalState.APPROVED ||
                  isV2VAPESelected
                }
              >
                <Typography>{`Swap`}</Typography>
              </Button>
            </RowBetween>
          ) : Number(formattedAmounts[Field.INPUT]) <=
            Number(maxAmountInput?.toExact()) ? (
            <Button
              color={isV2VAPESelected ? 'red' : 'blue'}
              onClick={() => {
                handlePresentAcceptModal();
              }}
              id="swap-button"
              data-testid="swap-button"
              style={{ height: '40px' }}
              disabled={
                !isValid ||
                approval !== ApprovalState.APPROVED ||
                isV2VAPESelected
              }
              fullWidth
            >
              <Typography>
                {isV2VAPESelected
                  ? 'Trading VAPE has been halted..'
                  : parsedAmount && swapInputError
                  ? `Price Impact Too High`
                  : `Swap`}
              </Typography>
            </Button>
          ) : (
            <Button
              size="base"
              color={
                isV2VAPESelected
                  ? 'red'
                  : !inputCurrencyId || !outputCurrencyId
                  ? 'light'
                  : 'red'
              }
              className="umami--click--swap-notenough-button"
              data-testid="swap-not-enough-tokens-btn"
              fullWidth
            >
              <Typography>{`${
                isV2VAPESelected
                  ? 'Trading VAPE has been halted..'
                  : !inputCurrencyId || !outputCurrencyId
                  ? 'Swap'
                  : 'Not enough tokens'
              }`}</Typography>
            </Button>
          )
        ) : (
          <ConnectWalletButton
            fullWidth
            data-testid="connect-wallet-btn-swap"
          />
        )}
      </div>
    );
  }, [
    account,
    approval,
    approvalSubmitted,
    approveCallback,
    formattedAmounts,
    handlePresentAcceptModal,
    inputCurrencyId,
    isV2VAPESelected,
    isValid,
    maxAmountInput,
    outputCurrencyId,
    parsedAmount,
    showApproveFlow,
    stablecoinPriceImpact,
    swapInputError,
  ]);

  const footer = useCallback(() => {
    return (
      isConnected && (
        <Container
          bg="ghost"
          stack="column"
          className="mt-2 w-[300px] cursor-pointer justify-center gap-4 text-center"
        >
          {isV2VAPESelected && (
            <Typography size="base" color="turquoise">
              Check announcement for more information
              <Link href={VAPE_V3_ANNOUNCEMENT} target="_blank">
                <span
                  style={{
                    color: 'yellow',
                    cursor: 'pointer',
                    fontSize: '10px',
                    justifySelf: 'flex-end',
                    paddingLeft: '10px',
                  }}
                >
                  PDF
                </span>
              </Link>
            </Typography>
          )}

          <Typography
            data-testid="swap-add-token-button"
            className="umami--click--swap-addtoken-button"
            color="turquoise"
            size="sm"
            onClick={() => handleWatchAsset(currencies[Field.OUTPUT])}
          >
            {`Add`}{' '}
            {currencies[Field.OUTPUT]
              ? currencies[Field.OUTPUT].symbol
              : 'USDC'}{' '}
            {`to wallet`}
          </Typography>
        </Container>
      )
    );
  }, [currencies, handleWatchAsset, isConnected, isV2VAPESelected]);
  return (
    <Container stack="row" className="shrink-0">
      <StyledInputCurrencyWrapper mt={isChartExpanded ? '24px' : '0'}>
        <AppBody>
          {currentStatePending && (
            <PendingSwapState
              inputCurrency={currencies[Field.INPUT]}
              outputCurrency={currencies[Field.OUTPUT]}
              inputFormattedCurrency={Number(
                formattedAmounts[Field.INPUT],
              ).toFixed(6)}
              outputFormattedCurrency={Number(formattedAmounts[Field.OUTPUT])}
            >
              <SummaryFooter />
            </PendingSwapState>
          )}

          {currentStateComplete && (
            <SuccessSwapState
              isStratosphereMember={isStratosphereMember}
              inputCurrency={currencies[Field.INPUT]}
              outputCurrency={currencies[Field.OUTPUT]}
              inputFormattedCurrency={formattedAmounts[Field.INPUT]}
              outputFormattedCurrency={formattedAmounts[Field.OUTPUT]}
              onTransactionCompleteClick={handleOnTransactionCompleteClick}
            >
              <SummaryFooter />
            </SuccessSwapState>
          )}

          {currentStateFailed && (
            <FailedSwapState
              inputCurrency={currencies[Field.INPUT]}
              outputCurrency={currencies[Field.OUTPUT]}
              inputFormattedCurrency={formattedAmounts[Field.INPUT]}
              outputFormattedCurrency={formattedAmounts[Field.OUTPUT]}
              onTransactionCompleteClick={handleOnTransactionCompleteClick}
              message={swapErrorMessage}
            >
              <SummaryFooter />
            </FailedSwapState>
          )}

          {currentStateInert && (
            <CurrencyForm
              {...{
                buttons,
                currencyInputPanel0,
                currencyInputPanel1,
                footer,
                globalSettings: () => <GlobalSettings />,
                isSwitchable: true,
                onCurrencySwitch: () => {
                  setApprovalSubmitted(false); // reset 2 step UI for approvals
                  onSwitchTokens();
                },
                preButtonFooter,
                title: 'Swap',
              }}
            />
          )}
        </AppBody>
      </StyledInputCurrencyWrapper>
    </Container>
  );
}

export default SwapViewForm;
